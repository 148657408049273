import React from "react"
import Link from "../components/Link"
import Box from "@mui/material/Box"
import T from "@mui/material/Typography"
import SEO from "../components/SEO"
import { navigate, PageProps } from "gatsby"
import Button from "../components/design-system/Button"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ButtonWrapper from "../components/ButtonWrapper"

const title = "Terms and conditions"

const TermsAndConditionsPage: React.FC<PageProps> = () => (
  <Box sx={{ maxWidth: 650 }}>
    <SEO title={title} />
    <T variant="h1" component="h1" sx={{ mb: 0 }}>
      {title}
    </T>
    <T variant="body2" sx={{ mb: "40px" }}>
      Last updated 26 May 2023
    </T>
    <T variant="h4" component="h2">
      Providing accurate information
    </T>
    <T variant="body2">
      {
        'You are responsible for providing complete and accurate information in your application. The Cambridge Colleges or the University of Cambridge (collectively, the "University") may check the information you provide if they believe that it is incomplete, false or inaccurate.'
      }
    </T>
    <T variant="body2">
      You must tell us if you believe that the information we hold about you is
      inaccurate or incomplete.
    </T>
    <T variant="body2">
      The University may also ask you or your referee to provide more
      information about your application.
    </T>
    <T variant="body2">
      The University has the right to cancel your application if:
    </T>
    <List className="MuiList-unordered" component="ul">
      <ListItem>
        <T variant="body2" component="span">
          the information you give is inaccurate or incomplete
        </T>
      </ListItem>
      <ListItem>
        <T variant="body2" component="span">
          you or your referee do not provide requested information on time
        </T>
      </ListItem>
      <ListItem>
        <T variant="body2" component="span">
          you or your referee provide inadequate information
        </T>
      </ListItem>
    </List>
    <T variant="body2">
      If we cancel your application and you have paid an application fee, we
      will not refund it.
    </T>
    <T variant="h4" component="h2">
      Your personal information
    </T>
    <T variant="body2">
      The University will use the personal information you provide to process
      your application and decide whether to offer you a place on the course you
      have applied for.
    </T>
    <T variant="body2">
      <Link href="https://www.information-compliance.admin.cam.ac.uk/data-protection/applicant-data">
        Read more about how we use your personal information.
      </Link>
    </T>
    <T variant="h4" component="h2">
      Processing your application
    </T>
    <T variant="body2">
      We will make all reasonable attempts to process applications and decisions
      efficiently and accurately. We will try to interview a high proportion of
      eligible applicants as long as they are available on the published
      interview dates. However, it is not possible to interview all applicants.
      Interviews for admission to the University are a mandatory part of the
      process. If you are not invited to interview, you will not be considered
      for admission.
    </T>
    <ButtonWrapper>
      <Button role="link" color="secondary" onClick={() => navigate(-1)}>
        Back
      </Button>
    </ButtonWrapper>
  </Box>
)

export default TermsAndConditionsPage
